var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "qmDialog",
          style: { "--maxHeight": _vm.maxHeight },
          attrs: {
            modal: _vm.modal,
            "close-on-click-modal": false,
            "append-to-body": "",
            top: "10vh",
            title: _vm.dialogTitle,
            visible: _vm.showDialog,
            "custom-class": _vm.customClass,
            fullscreen: _vm.fullscreen,
            width: _vm.width,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
            close: function ($event) {
              return _vm.closeDialog("cancel")
            },
          },
        },
        [
          _vm.showFullscreen
            ? _c("div", { staticClass: "positionabs" }, [
                _c("i", {
                  staticClass: "el-icon-full-screen",
                  on: {
                    click: function ($event) {
                      return _vm.fullClick()
                    },
                  },
                }),
              ])
            : _vm._e(),
          _vm._t("default"),
          _vm.showBtn
            ? _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _vm.showCopyBtn
                    ? _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.closeDialog("copy")
                            },
                          },
                        },
                        [_vm._v("复制")]
                      )
                    : _vm._e(),
                  _vm.showCancelBtn
                    ? _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.closeDialog("cancel")
                            },
                          },
                        },
                        [_vm._v("取消")]
                      )
                    : _vm._e(),
                  _vm.showConfirmBtn
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.closeDialog("confirm")
                            },
                          },
                        },
                        [_vm._v("确定")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }